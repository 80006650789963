@use 'base' as *;

.i-form {
  max-width: none;
}

.regform-done {
  box-shadow: none;
}

dl.i-data-list {
  max-width: none;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--bs-primary) !important;
}

.i-form.vertical .form-field,
.i-form .form-group.vertical .form-field {
  padding-left: 0;
}

.ui.input {
  max-width: none;

  & > input {
    max-width: none;
  }
}

.i-form.horizontal .form-field,
.i-form .form-group.horizontal .form-field {
  width: 80%;
}

.form-group .dropzone {
  width: 100%;
  border: 1px solid var(--bs-gray-400);
  border-radius: $default-border-radius;
  background-color: var(--bs-light);
}

.form-group .dropzone .dropzone-inner {
  display: flex;
  flex-direction: row;
  @media (max-width: 458px) {
    flex-direction: column;
  }
}
