.ui.primary.buttons .button,
.ui.primary.button {
  background-color: var(--bs-primary);
}
// change colors in the future
// .ui.button {
//   background-color: var(--bs-light);
// }

.ui-dialog-content {
  max-width: 800px;
}

// TEMA: make Event Search box span the full column width
#event-search-box > form.form.ui > label {
  width: 100%;
  max-width: 100%;
}
// TEMA END: make Event Search box span the full column width

// TEMA: make create new account modal look nice
#create-account-login-details {
  max-width: 80vw;
  width: 600px;
}
// TEMA END: make create new account modal look nice
