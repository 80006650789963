.toolbar.global-menu {
  background: transparent;
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
  justify-content: end;
  //   & > a:last-child {
  //     padding-right: 0px;
  //   }
  & > a,
  & > a:first-child,
  .toolbar.global-menu > a.arrow:last-of-type {
    border-radius: $btn-border-radius-lg;
  }
}
.toolbar.global-menu > a:hover,
.toolbar.global-menu > a.open {
  background: rgba(var(--bs-black-rgb), 0.5) !important;
}
