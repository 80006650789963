#outdated-browser {
  padding: 0 !important;
  height: auto !important;
  text-transform: none !important;

  & .vertical-center {
    color: #842029 !important;
    background-color: #f8d7da !important;
    border-color: #f5c2c7 !important;
    border: 1px solid;
    display: flex !important;
    flex-direction: column;
    padding: 1rem !important;
    width: 100%;
    position: relative;
    text-align: left !important;

    & h6 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-top: 0;
      margin-right: 2rem;
      color: #842029 !important;
    }

    & p:not(.last) {
      font-size: 1rem;
      line-height: 1.2;
      margin-right: 2rem;
      color: #842029 !important;
    }

    & .last {
      top: 1rem;
      right: 1rem;

      & > a#close-outdated-browser {
        line-height: 1;
        color: transparent;
        font-size: 1.5rem;

        &::before {
          display: block;
          position: absolute;
          line-height: 1;
          top: 0;
          right: 0;
          font-size: 1.5rem;
          font-family: 'icomoon-bootstrap', sans-serif;
          color: #842029;
          content: '\3b';
        }
      }
    }
  }
}
