.category-header {
  background-image: none;
  background-color: var(--bs-light);
  @media (max-width: 992px) {
    &.flexrow {
      & {
        flex-direction: column;
      }
    }

    & #category-toolbar {
      & > * {
        margin-top: 1rem;
        margin-right: 1rem;
        margin-left: 0px;
      }
      flex-direction: row;
      flex-wrap: wrap;
      // align-items: flex-start;
      max-height: none;
      & #search-box {
        margin-left: 0px;
        margin-right: 1rem;
        max-width: calc(100vw - 40px);
        min-width: 100px !important;
        width: calc(100vw - 40px);
      }
    }
  }
}
.category-title {
  color: var(--bs-primary);
}

.event-list h4.current-month span {
  // remove strange background color from current month
  background-color: transparent;
}
