@use 'base' as *;

#only-as-gedankenstuetze {
  background-color: #fafafa;
  border: 1px solid #ddd;
}

.infoline.info {
  background: var(--bs-light);
  border: solid 1px var(--bs-gray-400);
  border-radius: 0.375rem;
}

.action-box.draft-mode {
  max-width: none;
}

// Materials
.tree tr:not(.sub-tree):hover:not(.empty) > td {
  // override background color, instead select a inside table
  background-color: transparent;

  & > a {
    border-radius: $default-border-radius;
    background-color: var(--bs-light);
  }
  color: var(--bs-primary);
}

.h-fs-1 {
  height: $h1-font-size;
}

.tree tr.expandable {
  border-bottom: 1px var(--bs-primary) solid;
  position: relative;

  & > td {
    font-size: $font-size-base;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  & > td:first-child > span::before {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: var(--bs-light);
    color: var(--bs-primary);
  }

  &::after {
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    font-family: 'icomoon-bootstrap', sans-serif;
    transform: translateY(-50%) rotate(0deg);
    transform-origin: 50% 50%;
    content: '\24';
    transition: transform 200ms ease-out;
  }

  &.collapsed::after {
    transform: translateY(-50%) rotate(90deg);
  }
}

.tree tr.sub-tree > td > div > .tree {
  background-color: transparent;
}
