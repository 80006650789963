// Font settings
// $font-family-sans-serif: 'Inter', 'Helvetica Neue', 'Roboto', 'Arial', sans-serif;
$font-family-sans-serif: var(--bs-font-sans-serif);
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-base: $font-weight-normal !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;
$h1-font-size: $font-size-base * 2.5 !default;

// Color definitions
// $blue: #0d6efd;
// $green: #198754;
// $cyan: #0dcaf0;
// $yellow: #ffc107;
// $red: #dc3545;

// $white: #fff !default;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black: #000 !default;

// Theme color settings
// $primary: $blue !default;
// $secondary: $gray-600 !default;
// $success: $green !default;
// $info: $cyan !default;
// $warning: $yellow !default;
// $danger: $red !default;
// $light: $gray-100 !default;
// $dark: $gray-900 !default;

// border radius
$border-radius: 0.375rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 2rem !default;
$border-radius-pill: 50rem !default;
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-border-radius-lg: $border-radius-lg !default;
