#email-template-manager .email-template-wrapper .email-templates {
  width: auto;
}
#email-template-manager .email-template-wrapper .email-templates > ul > li {
  width: auto;
}
#email-template-manager .email-template-wrapper .email-templates > ul {
  padding-right: 0px;
}
.email-templates {
  .email-preview {
    .i-box-footer {
      margin-left: 0px;
      margin-right: 0px;

      .preformatted {
        white-space: normal;
      }
    }
  }
}

#abstract-notification-log {
  width: auto;
  min-width: 700px;
  .body.preformatted {
    white-space: normal;
  }
}
