div.event-header {
  // make important to override event style
  background: var(--bs-primary) !important;
}
.timetable-time.top-level .start-time {
  background-color: var(--bs-primary) !important;
}

.attachment-editor {
  width: auto;
}

form#attachment-upload-form.full-width .dropzone {
  width: auto;
}

.simpleTextAnnouncement {
    background: none !important;
    background-color: var(--bs-light) !important;
    border-bottom: none !important;
    border-top: solid 1px var(--bs-gray-400) !important;
    font-size: 1rem !important;
    color: var(--bs-dark) !important;
    padding: 0.5rem;
}
