.error-box {
    border-top: 1px solid var(--bs-gray-400);
    border-bottom: 1px solid var(--bs-gray-400);
    border-right: 1px solid var(--bs-gray-400);
    border-left: 1px solid var(--bs-gray-400);
    width: auto;
    max-width: 400px;
    background-color: var(--bs-light);
    // unchanged styles
    // font-size: 1.2em;
    // padding: 2em;
    // margin: 50px auto;
    // padding-top: 20px;
    // text-align: center;
    // border-radius: 0.5em;
    & h1 {
        color: var(--bs-primary);
    }
    & p {
        color: var(--bs-dark);
    }
}