div.session-bar {
  margin: 0px;
}

.toolbar .group .i-button:not(.hidden):first-of-type {
  border-bottom-left-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

#tz-selector-widget {
  text-align: start;
}

.settingsWidget {
  min-width: 100px;
}
