.i-box {
  box-shadow: none;

  & .i-box-header {
    background-color: var(--bs-light);
  }
  @media (max-width: 350px) {
    & .i-box-header {
      flex-direction: column;
    }
  }
}

.i-box .i-box-header .i-box-title {
  color: var(--bs-secondary);
}

.fixed-width {
  max-width: 100% !important;
}

// make action box mobile friendly
.action-box {
  @media (max-width: 768px) {
    & > .section {
      flex-flow: row wrap;

      & > .toolbar {
        width: 100%;
        padding-top: 0.5rem;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
}
