h1,
h2,
h3,
h4,
h5,
h6,
td,
dl,
ol,
blockquote {
  color: var(--bs-dark);
}
