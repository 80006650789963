.i-button {
  &.highlight,
  &.highlight:not(.label):not(.borderless):not(.text-color) {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
  }
}

.i-button:not(.label),
.categorynav .category-list .item .button-wrapper .action-button:not(.label),
.action-button:not(.label) {
  background: var(--bs-light);
  background-color: var(--bs-light);
  border-color: var(--bs-gray-400);
  color: var(--bs-dark);
}

.i-button:not(.label):hover, .categorynav .category-list .item .button-wrapper .action-button:not(.label):hover, .action-button:not(.label):hover {
    color: var(--bs-dark);
    border-color: var(--bs-gray-400);
    background-image: var(--bs-gray-400);
}