.button-bar {
  & .i-button.text-color:not(.color-on-hover) {
    color: var(--dark);
  }

  & .i-button:not(.label):hover {
    background-image: none;
    background-color: rgba(var(--bs-black-rgb), 0.2);
    border-color: rgba(var(--bs-black-rgb), 0.2) !important;
  }
}
