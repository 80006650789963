.user-dashboard .your-details {
  @media (max-width: 480px) {
    & {
      flex-direction: column;
    }
    & .actions {
      margin-top: 8px; // looks best, no particular reason
      margin-left: 0px;
    }
  }
}
