// use inter-variable everywhere
body,
h1,
h2,
h3,
h4,
h5,
.ui.button,
.ui.text.container,
.ui.header,
.ui.input > input,
.ui.list .list > .item .header,
.ui.list > .item .header,
.ui.steps .step .title,
.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='time'],
.ui.form input[type='text'],
.ui.form input[type='file'],
.ui.form input[type='url'],
.ui.form .fake-input,
.ui.menu,
.ui.message .header,
.ui.cards > .card > .content > .header,
.ui.card > .content > .header,
.ui.items > .item > .content > .header,
.ui.statistics .statistic > .value,
.ui.statistic > .value,
.ui.statistics .statistic > .label,
.ui.statistic > .label,
.ui.accordion .title:not(.ui),
.ui.modal > .header,
.ui.popup > .header,
.ui.search > .results .result .title,
.ui.search > .results > .message .header,
.ui.category.search > .results .category > .name,
.ui-widget input,
.ui-widget select,
.qtip-tipsy,
.qtip-tipped,
.banner .title,
.anchor-link,
.ui-widget,
.ui-widget textarea,
.ui-widget button,
.reviewing-page,
.category-info,
.speaker-list,
.conference-page,
.conference-page .description,
.conference-page .infogrid,
.contribution-list,
.track-review-list,
.ui.image.label.meeting-participant,
.paper-contribution-list,
.ui,
.fixed-width-standalone-page header .page-info,
.fixed-width-standalone-text-page header .page-info,
.search-page header .page-info,
.dialog-page header .page-info,
.management-page header .page-info,
.conference-page header .page-info,
.meeting-page header .page-info,
.category-calendar-page header .page-info,
.corner-message,
div.announcement-bar,
div.main-breadcrumb,
div.session-bar,
#filter-placeholder,
.qtip-default.add-field-qtip,
.side-menu,
.categorynav .category-list .search-results-list .title strong,
.timetable-title,
.participant-list-wrapper ul.participant-list,
.paper-content .spotlight-file-name,
.fixed-width-standalone-page header .page-description,
.fixed-width-standalone-text-page header .page-description,
.search-page header .page-description,
.dialog-page header .page-description,
.management-page header .page-description,
.conference-page header .page-description,
.meeting-page header .page-description,
.category-calendar-page header .page-description,
.i-box .i-box-header .i-box-title,
.i-timeline-item .i-timeline-item-box .i-box-header .i-box-title,
.categorynav .category-list .i-box-header .i-box-title,
.person-link-list .i-box-header .i-box-title,
#email-template-manager
  .email-template-wrapper
  .regform-section-sortable-placeholder
  .i-box-header
  .i-box-title,
.regform-section .i-box-header .i-box-title,
.regform-done .i-box-header .i-box-title,
.regform-section-sortable-placeholder .i-box-header .i-box-title,
.review-item-content .i-box-header .i-box-title,
.regform-section-title,
.toolbar.global-menu,
ul.steps .label,
.categorynav .category-list .item .title-wrapper .title,
.regFormSectionNewSection,
input.regFormTitleInputMgmt,
.i-box .i-box-header .i-box-description,
.i-timeline-item .i-timeline-item-box .i-box-header .i-box-description,
.categorynav .category-list .i-box-header .i-box-description,
.person-link-list .i-box-header .i-box-description,
#email-template-manager
  .email-template-wrapper
  .regform-section-sortable-placeholder
  .i-box-header
  .i-box-description,
.regform-section .i-box-header .i-box-description,
.regform-done .i-box-header .i-box-description,
.regform-section-sortable-placeholder .i-box-header .i-box-description,
.review-item-content .i-box-header .i-box-description,
textarea.regFormDescriptionInputMgmt,
.text-paper,
.abstract-content,
.agreement-form-sections .legal-body,
.md-preview-wrapper.display h1,
.md-preview-wrapper.display h2,
.md-preview-wrapper.display h3,
.md-preview-wrapper.display h4,
.md-preview-wrapper.display,
.md-preview-wrapper.edit h1,
.md-preview-wrapper.edit h2,
.md-preview-wrapper.edit h3,
.md-preview-wrapper.edit h4,
.md-preview-wrapper.edit,
.md-preview-wrapper.display.empty,
.md-preview-wrapper.edit.empty,
.i-form,
dl.i-data-list,
.bootstrap-header,
.bootstrap-body,
.bootstrap-header .header-title,
.bootstrap-header .header-subtitle,
.bootstrap-body .bootstrap-form .step-wrapper .step-container .step-title,
.bootstrap-body .bootstrap-form .step-wrapper .i-button,
.bootstrap-body
  .bootstrap-form
  .step-wrapper
  .categorynav
  .category-list
  .item
  .button-wrapper
  .action-button,
.categorynav
  .category-list
  .item
  .button-wrapper
  .bootstrap-body
  .bootstrap-form
  .step-wrapper
  .action-button,
.bootstrap-body .bootstrap-form .step-wrapper .action-button,
.groupTitle,
.groupTitleNoBorder,
.groupSubTitl,
.reviewingsubtitle,
div.impersonation-header,
.permissions-dialog .permissions label,
.material-list .folder .i-button.attachment,
.material-list .folder .categorynav .category-list .item .button-wrapper .attachment.action-button,
.categorynav .category-list .item .button-wrapper .material-list .folder .attachment.action-button,
.material-list .folder .attachment.action-button,
.material-list .folder .attachments,
.login-page,
.category-container,
.category-sidebar li h3,
.category-calendar-page .category-calendar-view,
.wmd-prompt-dialog,
.wmd-prompt-dialog > form > input[type='button'],
.wmd-prompt-dialog > div,
.popUpLabel,
.loadingPopup .text,
ul.sectionPopupList li.section .name,
.regFormSubtotal,
.timetableInfoBox,
div.timetablePreLoading .text,
#outdated-browser,
#outer li a,
.inner,
.col2,
.simpleTextAnnouncement {
  font-family: $font-family-sans-serif !important;
}

// use new icomoon font with bootstrap icons everywhere
[data-icon]::before,
[class^='icon-']::before,
[class*=' icon-']::before,
.wmd-button > span,
.user-list li::before,
ul.category-list li::before,
.i-box .ui-i-box-sortable-handle::before,
.i-timeline-item .i-timeline-item-box .ui-i-box-sortable-handle::before,
.categorynav .category-list .ui-i-box-sortable-handle::before,
.person-link-list .ui-i-box-sortable-handle::before,
#email-template-manager
  .email-template-wrapper
  .regform-section-sortable-placeholder
  .ui-i-box-sortable-handle::before,
.regform-section .ui-i-box-sortable-handle::before,
.regform-done .ui-i-box-sortable-handle::before,
.regform-section-sortable-placeholder .ui-i-box-sortable-handle::before,
.review-item-content .ui-i-box-sortable-handle::before,
.i-box ul.group-list > li.ui-sortable > .ui-sortable-handle > .handle::before,
.i-timeline-item
  .i-timeline-item-box
  ul.group-list
  > li.ui-sortable
  > .ui-sortable-handle
  > .handle::before,
.categorynav .category-list ul.group-list > li.ui-sortable > .ui-sortable-handle > .handle::before,
.person-link-list ul.group-list > li.ui-sortable > .ui-sortable-handle > .handle::before,
#email-template-manager
  .email-template-wrapper
  .regform-section-sortable-placeholder
  ul.group-list
  > li.ui-sortable
  > .ui-sortable-handle
  > .handle::before,
.regform-section ul.group-list > li.ui-sortable > .ui-sortable-handle > .handle::before,
.regform-done ul.group-list > li.ui-sortable > .ui-sortable-handle > .handle::before,
.regform-section-sortable-placeholder
  ul.group-list
  > li.ui-sortable
  > .ui-sortable-handle
  > .handle::before,
.review-item-content ul.group-list > li.ui-sortable > .ui-sortable-handle > .handle::before,
.i-button.signin .login-arrow::before,
.categorynav .category-list .item .button-wrapper .signin.action-button .login-arrow::before,
.signin.action-button .login-arrow::before,
.i-button.next::after,
.categorynav .category-list .item .button-wrapper .next.action-button::after,
.action-button::after,
a.arrow::after,
.i-button.arrow::after,
.categorynav .category-list .item .button-wrapper .arrow.action-button::after,
.arrow.action-button::after,
.i-button.color-button::before,
.categorynav .category-list .item .button-wrapper .color-button.action-button::before,
.color-button.action-button::before,
.dropup-menu::after,
.datetime-widget .timezone::before,
.date-widget .timezone::before,
.time-widget .timezone::before,
.datetime-widget .clear-pickers::before,
.date-widget .clear-pickers::before,
.time-widget .clear-pickers::before,
.i-label > .handle:first-child::before,
.toolbar.global-menu > a.arrow::after,
.info-message-box .icon::before,
.message-message-box .icon::before,
.highlight-message-box .icon::before,
.success-message-box .icon::before,
.warning-message-box .icon::before,
.error-message-box .icon::before,
.danger-message-box .icon::before,
.list .i-table th.header-sort-asc::before,
.list .i-table th.header-sort-desc::before,
.list .change-columns-width:not(.active)::before,
.list .change-columns-width.active::before,
.list-filter .i-label.title-wrapper > .actions > .visibility.enabled::before,
.menu-entries > li > .menu-entry > .i-label > .actions > .enabled::before,
.menu-entries > li > .menu-entry > .i-label > .actions > .not-enabled:hover::before,
.list-filter .i-label.title-wrapper > .actions > .visibility:not(.enabled)::before,
.menu-entries > li > .menu-entry > .i-label > .actions > .enabled:hover::before,
.menu-entries > li > .menu-entry > .i-label > .actions > .not-enabled::before,
.side-menu ul.menu-items > li.section .title-wrapper::before,
table.i-table th.header-sort-desc::before,
.i-table-widget th.header-sort-desc::before,
table.i-table th.header-sort-asc::before,
.i-table-widget th.header-sort-asc::before,
.i-table-widget .ui-sortable-handle::before,
i.info-helper::before,
.multiple-items-widget .sort-handle::before,
.multi-text-fields .handle::before,
.occurrences-widget .timezone::before,
.occurrences-widget .duration-info::before,
.person-link-list .person-row .sort-handle::before,
.sortable-list ul.enabled li .toggle-enabled::before,
.sortable-list ul.disabled li .toggle-enabled::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.ui-datepicker-trigger::before,
input.hasDatepicker::before,
.tree tr.expandable > td:first-child > span::before,
.tree tr.expandable.collapsed > td:first-child > span::before,
.attachments-tooltip-button::before,
.attachments-tooltip-button.open::before,
.auth-scopes li::before,
table.category-management th.sortable-column a.desc::before,
table.event-management th.sortable-column a.desc::before,
table.category-management th.sortable-column a.asc::before,
table.event-management th.sortable-column a.asc::before,
.designer-template-type-icon.template-icon-poster::before,
.designer-template-type-icon.template-icon-badge::before,
.action-menu .clones a::after,
.menu-entries > li > .menu-entry > .i-label > .entry-internal_link::before,
.menu-entries > li > .menu-entry > .i-label > .entry-user_link::before,
.menu-entries > li > .menu-entry > .i-label > .entry-plugin_link::before,
.menu-entries > li > .menu-entry > .i-label > .entry-page::before,
.menu-entries > li > .menu-entry > .i-label > .entry-separator::before,
.menu-entries > li > .menu-entry > .i-label > .actions > .default::before,
.menu-entries > li > .menu-entry > .i-label > .actions > .not-default::before,
.menu-entries > li > .menu-entry > .i-label > .actions > .edit-entry::before,
.menu-entries > li > .menu-entry > .i-label > .actions > .delete-entry::before,
[id^='wmd-bold-button'] span::before,
[id^='wmd-italic-button'] span::before,
[id^='wmd-link-button'] span::before,
[id^='wmd-quote-button'] span::before,
[id^='wmd-code-button'] span::before,
[id^='wmd-image-button'] span::before,
[id^='wmd-olist-button'] span::before,
[id^='wmd-ulist-button'] span::before,
[id^='wmd-heading-button'] span::before,
[id^='wmd-hr-button'] span::before,
[id^='wmd-undo-button'] span::before,
[id^='wmd-redo-button'] span::before,
.wmd-help-button::before,
.regform-section .section-sortable-handle::before,
.regform-section.manager-only .i-box-title::before,
.regform-field .email-info.warning::before,
.regform-field .email-info.error::before,
.regform-field .file-link::before,
.regform-field .remove-file::before,
.regform-field.editable:not(.disabled):hover .field-sortable-handle::before,
.regform-field.editable:not(.disabled):active .field-sortable-handle::before,
.regform-table tr:hover .table-sortable-handle::before,
.regform-table td:active .table-sortable-handle::before,
.registrant-stats > .i-box > .i-box-content > table tr.header-row td:first-child::before,
.i-timeline-item
  .registrant-stats
  > .i-timeline-item-box
  > .i-box-content
  > table
  tr.header-row
  td:first-child::before,
.categorynav
  .registrant-stats
  > .category-list
  > .i-box-content
  > table
  tr.header-row
  td:first-child::before,
.registrant-stats > .person-link-list > .i-box-content > table tr.header-row td:first-child::before,
#email-template-manager
  .email-template-wrapper
  .registrant-stats
  > .regform-section-sortable-placeholder
  > .i-box-content
  > table
  tr.header-row
  td:first-child::before,
.registrant-stats > .regform-section > .i-box-content > table tr.header-row td:first-child::before,
.registrant-stats > .regform-done > .i-box-content > table tr.header-row td:first-child::before,
.registrant-stats
  > .regform-section-sortable-placeholder
  > .i-box-content
  > table
  tr.header-row
  td:first-child::before,
.registrant-stats
  > .review-item-content
  > .i-box-content
  > table
  tr.header-row
  td:first-child::before,
.timeTableLegend .legendCloseButton::before,
#move-timetable-entry-dialog .days .scroll-btn::before,
.entry-attachments::before,
.event-user::before,
.user-list li .actions .event-person::before {
  font-family: 'icomoon-bootstrap', sans-serif !important;
  font-size: inherit;
  line-height: inherit;
}
