// include auth styles here, because they cannot be overridden by a plugin

.login-page {
  background-color: var(--bs-light);
  //   background-position: center;
  //   background-size: cover;
  margin: 0;
  padding: 0;
  text-align: center;
  display: table;
  position: relative;
  height: auto;
  min-height: 100vh;
  //   width: 100%;
}
